import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, List, ListItem, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import {
  Check,
  Block
} from '@material-ui/icons';
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class Timeclock extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: '',
      type: '',
      batch_id: '',
    };

    document.title = "Timeclock";
  }

  resetPage = () => {
    this.setState({
      user: '',
      type: '',
      batch_id: '',
    });
  }

  startProductivity = () => {
    const { type, user, batch_id } = this.state;

    let err = '';
    if (!user) err += 'User is required. \n';
    if (!type) err += 'Type is required. \n';
    if (type === 'PICKING') {
      if (!batch_id) err += 'For Picking, Batch Id is required. \n';
    }
    if (err) {
      this.context.alert(err);
      return;
    }

    this.startProductivityOnce({ type, user, batch_id });
  }
  startProductivityAjax = (req_data) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/productivity`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: req_data,
    });

    this.setState({loading: true});
    req.then(this.startProductivitySuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  }
  resetSubmitOnce = () => {this.startProductivityOnce = _.once(this.startProductivityAjax);} // reset the submit picking once after the ajax call returns
  startProductivityOnce = _.once(this.startProductivityAjax) 
  startProductivitySuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    // this.context.snackDisplay("Task started.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.context.snackDisplay("Task started.");
    this.resetPage();
  }
  stopProductivity = () => {
    const { user } = this.state;
    let err = '';
    if (!user) err += 'User is required. \n';
    if (err) {
      this.context.alert(err);
      return;
    }
  
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/stopproductivity`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        user
      },
    });

    this.setState({loading: true});
    req.then(this.stopProductivitySuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  stopProductivitySuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    // this.context.snackDisplay("Task stopped.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.context.snackDisplay("Task stopped.");
    this.resetPage();
  }

  render() {
    const { classes } = this.props;
    const { user, type, batch_id } = this.state;

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
      
        <GridItem xs={12} sm={6}>
          <CardContainer>
            <CustomInput
              labelText='User'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                type: 'password',
                value: user,
                onChange: (e)=>this.setState({user: e.target.value})
              }}
            />

            <FormControl fullWidth required className={classes.customInput}>
                <InputLabel shrink>Search By</InputLabel>
                <Select
                  value={type}
                  onChange={(e)=>this.setState({type: e.target.value})}
                >
                  <MenuItem value='B2CPRODUCTION'>B2CPRODUCTION</MenuItem>
                  <MenuItem value='B2BPRODUCTION'>B2BPRODUCTION</MenuItem>
                  <MenuItem value='BOXZOOKAPROJECT'>BOXZOOKAPROJECT</MenuItem>
                  <MenuItem value='DRIVER'>DRIVER</MenuItem>
                  <MenuItem value='INBOUNDPRODUCTION'>INBOUNDPRODUCTION</MenuItem>
                  <MenuItem value='INVENTORYCYCLECOUNT'>INVENTORYCYCLECOUNT</MenuItem>
                  <MenuItem value='INVENTORYRODUCTION'>INVENTORYRODUCTION</MenuItem>
                  <MenuItem value='PICKING'>PICKING</MenuItem>
                  <MenuItem value='TRAINER'>TRAINER</MenuItem>
                  <MenuItem value='PUTAWAY QA'>PUTAWAY QA</MenuItem>
                </Select>
              </FormControl>

              {
                (type === 'PICKING') && <CustomInput
                  labelText="Batch Id"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput
                  }}
                  labelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    value: batch_id,
                    onChange: (e)=>this.setState({batch_id: e.target.value})
                  }}
                />
              }

            <Button onClick={this.startProductivity} className={classes.button}>Submit</Button>

            <Button onClick={this.stopProductivity} style={{float: 'right'}} color="secondary" className={classes.button}>Stop</Button>
          </CardContainer>
        </GridItem>
      </Grid>
    );
  }
}
export default withStyles(styles)(Timeclock);
